@charset "utf-8";
@import 'import/var';
@import 'import/animation';

/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/

.wrapper {
}

.root {
	// min-width: $pc_header_height;
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
	top: 0;
	left: 0;

	padding-top: $pc_header_height;
	// @media #{$pc-l} {
	// }
	// @media #{$pc-m} {
	// 	padding-top: 80px;
	// }
	// @media #{$pc-s} {
	// 	padding-top: 60px;
	// }
}

@mixin container($width: $pc_container_width) {
	width: 100%;
	box-sizing: border-box;
	// padding-left: $pc_padding;
	// padding-right: $pc_padding;
	margin-left: auto;
	margin-right: auto;
	max-width: $width;

	// @media screen and (max-width: 1119px) {
	// }
	// @media screen and (max-width: 999px), print {
	// 	padding-left: $pc_padding;
	// 	padding-right: $pc_padding;
	// }
}

.pc_container,
.pc_tb_container,
.container {
	@include container;
	&-relative {
		position: relative;
		top: 0;
		left: 0;
	}
	&-sm {
		@include container(600px);
	}
	&-sm2 {
		@include container(300px);
	}
}
.pc_padding,
.padding {
	padding-left: $pc_padding;
	padding-right: $pc_padding;
}

.main_content_area {
	padding-top: 30px;
	position: relative;
	top: 0;
	left: 0;
	padding-bottom: 80px;

	display: flex;
	justify-content: space-between;

	&-cindex {
		display: block;
	}
	// background:
	// 	url('../img/bg_sub_top_color.png') 0 0 repeat-x,
	// 	url('../img/bg_sub_bottom_color.png') 0 0 repeat;
}

.main_column {
	order: 2;
	width: 100%;
	box-sizing: border-box;
}

.sub_column {
	order: 1;
	width: 260px;
	flex-basis: 260px;
	flex-shrink: 0;
	flex-grow: 0;
	margin-right: 30px;
	box-sizing: border-box;
	overflow: hidden;
	// background-color: red;
}

.main_content {
	font-size: $pc_default_font_size;
	padding-bottom: 140px;
}

.scroll_to_top {
	right: $pc_padding;
	// right: 0;


	@media screen and (min-width: 1120px) {
		right: 120 + $pc_padding;
	}
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

*/
.pc_none,
.root .pc_none {
	display: none;
}
.block,
.pc_block {
	display: block;
}
@media screen and (min-width: 1000px) and (max-width: 1099px), print {
	.pc_s_block {
		display: block;
	}
}
.tel_link,
.tel_link-no {
	pointer-events: none;
	cursor: default;
	color: inherit;
	.root &,
	.root &:hover {
		text-decoration: none;
		color: inherit;
	}
}
.sp_click,
.pc_no_click {
	pointer-events: none;
	cursor: default;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************



## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/


/**************************

## - 段組設定 2列 3列 4列

PCの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .pc_column2**

	<ul class="pc_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .pc_column3**

	<ul class="pc_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .pc_column4**

	<ul class="pc_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/
$column: (2, 3, 4);

@each $num in $column {
	.pc_column#{$num} > *:nth-child(#{$num}n) {
		padding-bottom: 20px;
	}
	.pc_column#{$num}-no_mb > *:nth-child(#{$num}n) {
		padding-bottom: 0em;
	}
	.pc_column#{$num} > *:last-child {
		padding-bottom: 0;
	}
}

.set-2::before,
.set-2::after {
	content: '';
	display: table;
}

.set-2::after,
.set-3::after {
	clear: both;
}


.pc_column2 {
	@include columns(2, 40px);
}
.pc_column3 {
	@include columns(3, 40px);
}
.pc_column4 {
	@include columns(4, 20px);
}

.pc_column2_2 {
	@include flex_columns(2, 360px, 10px);
	> * {
		&:nth-child(2n) {
			padding-bottom: 20px;
		}
		&:last-child {
			padding-bottom: 0;
		}
	}
}

.set {
	@include clearfix;

	@include set(410px, 40px);

	&-2 {
		@include set(200px, 40px);
	}
	&-3 {
		@include set(380px, 20px);
	}
	&-4 {
		@include set(380px, 20px);
	}
	&-5 {
		@include set(260px, 40px);
	}
}

.footer {
	&__2 {
		position: relative;
		top: 0;
		left: 0;
	}
	// &-scroll_to_top_stop {
	// 	.scroll_to_top {
	// 		position: absolute;
	// 		top: ($scroll_to_top_height + 20) * -1;
	// 	}
	// }
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/

.header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	box-sizing: border-box;

	&__1 {
		.container {
			display: flex;
			justify-content: space-between;
			height: 90px;

		}
		&__a {
			padding-top: 10px;
		}
		&__b {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			padding-bottom: 8px
		}
	}
	&__2 {

	}
	&__logo {
		width: 300px;
		height: 80px;
	}
	&__sp_tellink {
		display: none;
	}

	&__link_area {
		margin-right: 20px;
		ul {
			display: flex;
			li {
				line-height: 1;
				&:not(:last-child) {
					margin-right: .7em;
				}
				a {
					@include rodin('DB');
					color: palette('color', 'default');
					font-size: $fontsize15;
					padding-right: 24px;
					position: relative;
					top: 0;
					left: 0;
					&._medical {
						&::after {
							@include bg-inline('../img/icon/personal.png', 20px, 18px);
						}
					}
					&._contact {
						&::after {
							@include bg-inline('../img/icon/mail.png', 20px, 16px);
						}
					}
					&::after {
						position: absolute;
						bottom: .1em;
						right: 0;
					}
					&:hover {
						text-decoration: underline;
						color: palette('link', 'default');
					}
				}
			}
		}
	}
	&__tel_area {
		display: flex;
		flex-direction: column;
		// justify-content: center;
		align-items: flex-end;
		margin-right: 20px;
		// margin-bottom: -2px;

		.label {
			@include rodin('M');
			font-size: $fontsize15;
		}
		.tel {
			width: 230px;
			height: 26px;

			&,
			img {
				display: block;
				max-width: 100%;
			}
			// @include myriad_b;
			// font-size: 32px;
			// line-height: 1;
			// padding-left: 20px;
			// position: relative;
			// top: 0;
			// left: 0;
			// &::before {
			// 	@include bg-block('../img/icon/tel_black.png', 16px, 26px);
			// 	position: absolute;
			// 	top: 0;
			// 	left: 0;
			// }
		}
	}
	&__search {
		a {
			@include on_opacity;
			display: block;
			width: 30px;
			height: 30px;
			background-color: palette('blue', 'default');
		}
		a,
		img {
			display: block;
			max-width: 100%;
		}
	}

	.open_menu {
		display: none;
	}
}

$gnav_height: 64px;
.gnav {
	&__ul {
		display: flex;
		justify-content: center;
		height: $gnav_height;
	}
	&__li {
		// justify-content: center;
		// align-items: flex-start;
		position: relative;
		top: 0;
		left: 0;
	}
	&__a {
		@include rodin('DB');
		font-size: $fontsize15;
		letter-spacing: .1em;
		padding-left: 15px;
		padding-right: 15px;
		height: $gnav_height;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #fff;

		&::before {
			@include bg-block('../img/icon/arrow/gnav_top.png', 20px, 10px);
			position: absolute;
			bottom: 5px;
			left: 50%;
			margin-left: -10px;
			// background-color: #fff;
		}

		&.active,
		&.hover,
		&:hover {
			background-color: palette('blue', 'color');
			color: palette('link', 'default');
		}
	}

	$gnavisub-widths: 300px;
	$border_color: palette('blue', 'border');
	&__sub {
		display: none;
		visibility: hidden;
		position: absolute;
		top: $gnav_height;
		left: 0;
		z-index: 99;

		.inited & {
			visibility: visible;
		}

		&__container {
			position: relative;
			// width: $max_width - ($pc_padding * 2);
			width: $gnavisub-widths;
			// width: 100%;
			padding-top: 0;
			top: 0;
			left: 0;
			box-sizing: border-box;
			transition: opacity .5s ease-in-out;
			z-index: 10;
			// background-color: #fff;
			// &::before {
			// 	@include bg-block('../img/icon/arrow/gnav_top.png', 20px, 10px);
			// 	position: absolute;
			// 	top: -9px;
			// 	// left: 0;
			// 	margin-left: -10px;
			// 	background-color: #fff;
			// }
			&-6 {
				.gnav__sub__ul1 {
					left: -503px;
				}
			}

			&-column2 {
				width: $gnavisub-widths * 2;

				.gnav__sub__ul1 {
					display: flex;
					flex-wrap: wrap;
					background-color: darken(palette('blue', 'color'), 5);

					> .li1 {
						width: $gnavisub-widths;
						&:nth-child(odd) {
							border-right: 1px solid palette('blue', 'border');
							box-sizing: border-box;
						}
					}
				}
			}
		}
		&__ul1 {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			overflow: hidden;
			// background-color: palette('background');
			padding: 0;
			// box-shadow: 0 1px 3px 0 rgba(#000, .2);
			// border-bottom: 3px solid palette('green', '2');
			border-top: 1px solid palette('blue', 'border');

		}
		.li1 {
			border-bottom: 1px solid $border_color;
			position: relative;
			top: 0;
			left: 0;

			.toggle,
			.accordion__content {
				display: none;
			}
			// &-comment {
			// 	> span {
			// 		display: block;
			// 		font-size: $fontsize14;
			// 		// color: #333;
			// 		// background: palette('beige');
			// 		padding: 4px 35px 1px 15px;
			// 		box-sizing: border-box;
			// 	}
			// }
			&.active {

			}

		}
		$pdleft: 15px;
		// $before_left: 13px;

		.a1 {
			@include rodin('M');
			background-color: palette('blue', 'color');
			display: block;
			padding-top: 10px;
			padding-bottom: 9px;
			// padding-right: 35px;
			// padding-right: 10px;
			padding-left: $pdleft;
			min-height: 40px;
			box-sizing: border-box;
			color: palette('link', 'default');
			// background-color: #fff;
			line-height: (20 / 15);
			font-size: $fontsize15;
			position: relative;
			top: 0;
			left: 0;
			padding-right: 15px;

			&::after {
				@include bg-block('../img/icon/arrow/gnav_sub.png', 10px, 15px);
				position: absolute;
				top: 50%;
				right: 12px;
				margin-top: -5px;
			}
		}
		li {
			&.active > a,
			> a:hover {
				font-weight: bold;
				text-decoration: none;
				background-color: lighten(palette('blue', 'color'), 10);
				color: palette('blue', '2');
			}
		}
		.li2,
		.li3 {
			display: none;
		}
		&-show {
			display: block;
			.anime {
				animation-name: gnav_sub;
				animation-duration: .2s;
				animation-timing-function: $easeOutCubic;
				animation-fill-mode: forwards;
			}
		}
	}
}

.sub_banner_area {
	margin-top: 20px;
}

.img_left{
	float: left;
	padding-right: 20px;
}
.img_right{
	float: right;
	padding-left: 20px;
}

.dock_td{
    width: 15%;
    vertical-align: middle;
    text-align: right;
}



.remodal_pc_search {
	border-radius: 10px;
	.h-search {
		@include rodin('DB');
		font-size: $fontsize24;
		color: palette('blue', 'default');
		margin-bottom: .5em;
		letter-spacing: .1em;
		text-align: center;
	}

	.search_close_button {
		margin-top: 1em;
		min-width: 12em;
		text-align: center;
		padding-right: 32px;
	}
}

.search {
	$height: 46px;
	// @include icon-sprite2($icon-search);
	display: block;
	position: relative;
	top: 0;
	left: 0;
	box-sizing: border-box;
	width: 100%;

	&::before {
		left: 4px;
		z-index: 2;
	}

	&__text {
		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		// border-radius: 6px;
		border: 1px solid #ccc;
		background-color: #e1e1e1;
		max-width: 100%;
		box-sizing: border-box;
		z-index: 1;
		padding-left: 8px;
		height: $height;
		line-height: $height;
		font-size: $fontsize16;
		-webkit-appearance: none;
		overflow: hidden;

		&:focus {
			outline: none;
		}
	}
	&__submit {
		border: 0 none;
		cursor: pointer;
		-webkit-appearance: none;
		position: absolute;
		top: 1px;
		right: 1px;
		background-color: #e1e1e1;
		width: $height - 2;
		height: $height - 2;
		padding: 0;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		transition: opacity .1s ease-in-out;
		&:hover {
			&::before {
				opacity: .75;
			}
		}
		&::before {
			@include bg-block('../img/pc/header_search.png', 30px, 30px);
			margin: auto;
		}
	}
}

