@charset "utf-8";

@mixin bg-inline($url, $width, $height) {
	content: '';
	display: inline-block;
	background: url($url) 0 center no-repeat transparent;
	background-size: $width $height;
	width: $width;
	height: $height;
}
@mixin bg-block($url, $width, $height) {
	content: '';
	display: block;
	background: url($url) 0 0 no-repeat transparent;
	background-size: $width $height;
	width: $width;
	height: $height;
}

@mixin bg($url, $width, $height) {
	background: url($url) 0 0 no-repeat transparent;
	background-size: $width $height;
	width: $width;
	height: $height;
}

// @font-face {
// 	font-family: 'San Francisco';
// 	font-weight: 400;
// 	src: url("../font/sanfranciscodisplay-regular-webfont.woff");
// }


@mixin button_hover {
	color: $button_color_hover;
	background-color: $button_color_bg_hover;
	transition: background-color .2s ease-out;
}

@mixin vertical-align-middle($is_center: false) {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	@if($is_center == true) {
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
	}
}

@mixin columns_fix($width, $float: left) {
	width: $width;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: $float;
}
@mixin columns_flex($width, $float: left, $padding: 0) {
	width: 100%;
	box-sizing: border-box;
	float: $float;
	@if $float == left {
		padding-right: $width + $padding;
		margin-right: $width * -1;
	} @else if $float == right {
		padding-left: $width + $padding;
		margin-left: $width * -1;
	}
}

@mixin columns($column, $padding: 20px, $max_width: null) {
	@include clearfix;
	> * {
		@if $max_width != null {
			width: round(($max_width - ($padding * ($column - 1))) / $column);
		} @else {
			width: 100% / $column;
		}
		float: left;
		box-sizing: border-box;
	}
	@for $i from 1 through ($column) {
		$ii: $column - $i;
		@if ($column != $i) {
			> *:nth-child(#{$column}n + #{$i}) {
				@if ($i == 1) {
					clear: left;
					padding-right: round($padding / $column * $ii);
				} @else {
					padding-left: round($padding / $column * ($i - 1));
					padding-right: round($padding / $column * $ii);
				}
			}
		} @else {
			> *:nth-child(#{$column}n) {
				padding-left: round($padding / $column * ($i - 1));
			}
		}
	}
}

@mixin flex_columns($column, $width, $padding, $is_cancel: false) {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	> * {
		width: $width;
		margin-right: $padding;

		@if $is_cancel {
			padding-left: 0;
			padding-right: 0;
			float: none;
		}

		&:nth-child(#{$column}n) {
			margin-right: 0;
		}
	}
}

@mixin flex_column_column($column, $width, $padding) {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	> * {
		width: $width;
		box-sizing: border-box;
		display: flex;
	}
	@for $i from 1 through ($column) {
		$ii: $column - $i;
		@if ($column != $i) {
			> *:nth-child(#{$column}n + #{$i}) {
				@if ($i == 1) {
					clear: left;
					padding-right: round($padding / $column * $ii);
				} @else {
					padding-left: round($padding / $column * ($i - 1));
					padding-right: round($padding / $column * $ii);
				}
			}
		} @else {
			> *:nth-child(#{$column}n) {
				padding-left: round($padding / $column * ($i - 1));
			}
		}
	}
}


/* simple clearfix */
%clearfix {
	&::before,
	&::after {
		content: '';
		display: table;
	}
	&::after {
		clear: both;
	}
}
@mixin clearfix {
	&::before,
	&::after {
		content: '';
		display: table;
	}
	&::after {
		clear: both;
	}
}


@mixin on_opacity {
	&:hover {
		text-decoration: none;
		opacity: 0.7;
		transition: 0.2s opacity;
	}
}

/**************************
@mixin for sprite
*/

@mixin sprite2-width($sprite) {
	width: nth($sprite, 5) / 2;
}

@mixin sprite2-height($sprite) {
	height: nth($sprite, 6) / 2;
}

@mixin sprite2-position($sprite) {
	$sprite-offset-x: nth($sprite, 3) / 2;
	$sprite-offset-y: nth($sprite, 4) / 2;
	background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite2-size($sprite) {
	background-size: nth($sprite, 7) / 2 nth($sprite, 8) / 2;
}

@mixin sprite2($sprite) {
	@include sprite-image($sprite);
	@include sprite2-position($sprite);
	@include sprite2-width($sprite);
	@include sprite2-height($sprite);
	@include sprite2-size($sprite);
}

@mixin icon-sprite2-inline($sprite, $top: 'middle', $before: 'before', $is_block: false, $margin-right: .5em) {
	@include icon-sprite2($sprite, $top, $before, $is_block, $margin-right)
}

@mixin icon-sprite2($sprite, $top: 'middle', $before: 'before', $is_block: true, $margin-right: .5em) {
	position: relative;
	left: 0;
	@if ($is_block == true) {
		&::#{$before} {
			@include sprite2($sprite);
			content: '';
			display: block;
			position: absolute;
			left: 0;
			@if $top == 'middle' {
				top: 50%;
				transform: translateY(-50%);
			} @else {
				top: $top;
			}
		}
	} @else {
		&::#{$before} {
			@include sprite2($sprite);
			content: '';
			display: inline-block;
			@if $before == before {
				margin-right: $margin-right;
			} @else {
				margin-left: $margin-right;
			}
			@if $top != 'middle' {
				position: relative;
				top: $top;
				left: 0;
			}
		}
	}
}

// @mixin replace-sprite2($sprites, $image_height, $width: auto, $area_height: $image_height, $is_center: false, $before: before) {
// 	@include hide-text;
// 	display: block;
// 	height: $area_height;
// 	box-sizing: border-box;
// 	width: $width;
// 	&::#{$before} {
// 		@include sprite2($sprites);
// 		$image_height: $image_height;
// 		$mt: round(($area_height - $image_height) / 2);
// 		$mb: $area_height - $image_height - $mt;
// 		content: '';
// 		display: block;
// 		margin-top: $mt;
// 		margin-bottom: $mb;
// 		// height: $image_height;

// 		@if $is_center == true {
// 			position: absolute;
// 			top: 0;
// 			left: 50%;
// 			transform: translateX(-50%);
// 		}
// 	}
// }

@mixin default_image($width: 100%) {
	// border-radius: $border_radius;
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: $width;
}

@mixin set($width, $padding) {
	.resize.right {
		@include columns_flex($width, right, $padding);
	}
	.fix.left {
		@include columns_fix($width, left);
	}

	.resize.left {
		@include columns_flex($width, left, $padding);
	}
	.fix.right {
		@include columns_fix($width, right);
	}
	.fix,
	.resize {
		> img:not(.cancel) {
			@include default_image($width);
		}
	}
}

@mixin default_scroll_anime($delay: 0s) {
	transition: all 1.4s $easeOutExpo $delay;
	&.init {
		opacity: 0;
		transform: translateY(-5%);
	}
}

@mixin cancel() {
	padding: 0;
	margin: 0;
	list-style: none;
	width: inherit;
	height: inherit;
	border: 0 none;
	color: inherit;
	vertical-align: baseline;
	text-align: inherit;
	font-size: inherit;
	font-weight: normal;
	line-height: inherit;
	overflow: visible;
	border-radius: 0;
	position: static;
	left: auto;
	top: auto;
	clear: inherit;
	content: normal;
	min-height: initial;
	max-height: initial;
	float: none;
	transform: none;
	background: none center center / center center repeat-none transparent;
	box-sizing: content-box;
}

@mixin cancel_all() {
	@include cancel;
	&::before,
	&::after {
		@include cancel;
	}
}

@mixin cancel_list() {
	.main_content & {
		padding-left: 0;
		li {
			margin-bottom: 0;
			&::before {
				display: none;
			}
		}
	}
}

@mixin width_100vw {
	width: auto;
	max-width: none;
	margin-right: calc(50% - 50vw);
	margin-left: calc(50% - 50vw);
}

@mixin sp_unindent {
	margin-left: -1 * $sp_padding;
	margin-right: -1 * $sp_padding;
	// width: 100vw;
}

@mixin pc_unindent {
	margin-left: -1 * $pc_padding;
	margin-right: -1 * $pc_padding;
}

