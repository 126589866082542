@charset "utf-8";

@keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}
@keyframes fade-out {
	0% {
		display: block;
		opacity: 1;
	}
	99% {
		display: block;
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
	}
}

@keyframes gnav_sub {
	0% {
		opacity: 0;
		transform: scale(.95) translateY(-5%);
	}
	100% {
		transform: scale(1) translateY(0);
	}
}
// @keyframes zoom-in {
// 	0% {
// 		transform: scale(1);
// 	}
// 	100% {
// 		transform: scale(1.5);
// 	}
// }
// @keyframes zoom-in-hover {
// 	0% {
// 		transform: scale(1);
// 	}
// 	100% {
// 		transform: scale(1.1);
// 	}
// }

// @keyframes sub_menu_fade_in {
// 	0% { opacity: 0; }
// 	100% { opacity: .5; }
// }

// @keyframes pop-up {
// 	0% {
// 		transform: scale(1);
// 	}
// 	100% {
// 		transform: scale(1.1);
// 	}
// }

@keyframes _on_opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// @keyframes bird_group2 {
// 	0% {
// 		left: 95vw;
// 	}
// 	95% {
// 		left: -10vw;
// 	}
// 	96% {
// 		visibility: hidden;
// 	}
// 	97% {
// 		visibility: hidden;
// 		left: 110vw;
// 	}
// 	98% {
// 		display: block;
// 		left: 110vw;
// 	}
// 	100% {
// 		left: 0;
// 	}
// }

// @keyframes slide-up {
// 	0% {
// 		transform: translateY(100%);
// 	}
// 	100% {
// 		transform: translateY(0);
// 	}
// }

