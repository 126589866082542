@charset "utf-8";

// @import 'susy';
// $susy: (
// 	columns: 12,
// 	gutters: 1 / 4,
// 	math: magic,
// 	output: float,
// 	gutter-position: inside,
// );

@function palette($palette-group, $palette-type: 'default') {
	@return map-get(map-get($palettes, $palette-group), $palette-type);
}

$palettes: (
	'red': (
		'default': #ec4949,
		'alert': #ff8888,

		// 'icon': #f38676,
		// 'new': #e44444,
	),
	'blue': (
		'default': #0f4c81,
		'bg': #ebfaff,
		'bg2': #d3f5fe,
		'bg3': #65c8e1,
		'bg4': #def7ff,
		'bg5': #d8e0f7,
		'bg6': #f3f6ff,
		'bg7': #d2f5ff,
		'bg8': #bae7e3,
		'color': #76d4d5,
		'color2': #475c7c,
		'color3': #8b9fc1,
		'border': #97f8f9,
	),
	'pink': (
		'default': #fff5f2,
		'a': #ff8888,
	),
	'yellow': (
		'default': #fffde0,
		'bg': #fffaf1,
	),
	'orange': (
		'default': #ff8888,
	),
	'green': (
		'default': #000,
	),
	'gray': (
		'default': #000,
	),
	'brown': (
		'default': #000,
	),
	'marker': (
		'default': #000,
	),

	'border': (
		'default': #ddd,
		'1': #f3f6ff,
	),


	'li': (
		'ul': #444,
		'ol': #444,
	),

	// 'news': (
	// 	'news': #805938,
	// 	'important': #d34c31,
	// 	'event': #48932d,
	// 	'recruit': #003163,
	// 	'medical': #f35560,
	// ),

	'color': (
		'default': #222222,
		'p': #222222,
		'em': #404040,

		'default_bg': #fff,
		'placeholder': #999,
		'breadcrumbs': #000,
		'outline': #a6a6a6,
		'hover_bg': #2c2c96,
	),
	'link': (
		'default': #0f4c81,
		'hover': #3f9784,
		'bg': #eff7f6,
		'color': #2d8fe5,
		'blue': #2d8fe5,
		'red': #d64747,
		'black': #000,
	),
	'button': (
		'hover': #fff,
		'bg': #7f7f7f,
		'bg_hover': #000,
	),
	'table': (
		'border': #cccccc,
		'bg': #f3f6ff,
		'bg2': #f3f6ff,
	),
);

// $pc_container_width_lg: 1200px;
$pc_container_width: 1200px;
$pc_container_width_sm: 800px;
$pc_contnet_width: 900px;

$pc_header_height: 154px;
$pc_header_height_fixed: 100px;
$sp_header_height: 61px;

$border_radius: 5px;
$image_radius: 25px;

$scroll_to_top_width: 100px;
$scroll_to_top_height: 100px;
$pc_padding: 20px;
$sp_padding: 10px;

// $sp_set_image_max_width: 410px;

// $pc_main_content_width: 720px;

// $pc_max: "screen and (min-width: 1900px), print";
// $pc_l1: "screen and (min-width: 1500px) and (max-width: 1899px), print";
// $pc_l2: "screen and (min-width: 1380px) and (max-width: 1499px), print";
// $pc_l3: "screen and (min-width: 1150px) and (max-width: 1379px), print";
// $pc_l4: "screen and (min-width: 1000px) and (max-width: 1149px), print";


// $pc_1440: "screen and (min-width: 1440px), print";
// $pc_1439: "screen and (min-width: 1000px) and (max-width: 1439px), print";

$pc: "screen and (min-width: 1000px), print";
$sp: "screen and (max-width: 999px)";
// $pc: "screen and (min-width: 1000px), print";
// $sp: "screen and (max-width: 999px)";

// $pc-l: "screen and (min-width: 1200px), print";
// $pc-m: "screen and (min-width: 1100px) and (max-width: 1199px), print";
$pc-1000: "screen and (min-width: 1000px), print";
$pc-900: "screen and (min-width: 900px), print";
$sp-879: "screen and (max-width: 879px)";
$sp-340: "screen and (max-width: 340px)";


$ipc: "screen and (min-width: 768px)";
$isp: "screen and (max-width: 767px)";


$pc-l: "screen and (min-width: 1200px), print";
$pc-s: "screen and (min-width: 1000px) and (max-width: 1199px), print";
$sp-l: "screen and (min-width: 768px) and (max-width: 999px)";
$sp-m: "screen and (min-width: 450px) and (max-width: 767px)";
$sp-s: "screen and (max-width: 449px)";

$pc-ls: "screen and (min-width: 1000px), print";
$sp-lm: "screen and (min-width: 450px) and (max-width: 999px)";
$sp-ms: "screen and (max-width: 767px)";


$fontsize8: 0.600rem;
$fontsize9: 0.563rem;
$fontsize10: 0.625rem;
$fontsize11: 0.688rem;
$fontsize12: 0.750rem;
$fontsize13: 0.813rem;
$fontsize14: 0.875rem;
$fontsize15: 0.938rem;
$fontsize16: 1rem;
$fontsize17: 1.063rem;
$fontsize18: 1.125rem;
$fontsize19: 1.188rem;
$fontsize20: 1.250rem;
$fontsize21: 1.313rem;
$fontsize22: 1.375rem;
$fontsize23: 1.438rem;
$fontsize24: 1.500rem;
$fontsize25: 1.563rem;
$fontsize26: 1.625rem;
$fontsize27: 1.688rem;
$fontsize28: 1.750rem;
$fontsize29: 1.813rem;
$fontsize30: 1.875rem;
$fontsize31: 1.938rem;
$fontsize32: 2rem;
$fontsize33: 2.063rem;
$fontsize34: 2.125rem;
$fontsize35: 2.188rem;
$fontsize36: 2.250rem;


$pc_default_font_size: $fontsize16;
$sp_default_font_size: $fontsize14;

$pc_default_line_height: 2;
$sp_default_line_height: 2;


$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);

$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

@import 'mixins';
