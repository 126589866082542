@charset "UTF-8";
/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
/* simple clearfix */
/**************************
@mixin for sprite
*/
@keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		display: block;
		opacity: 1;
	}
	99% {
		display: block;
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
	}
}

@keyframes gnav_sub {
	0% {
		opacity: 0;
		transform: scale(0.95) translateY(-5%);
	}
	100% {
		transform: scale(1) translateY(0);
	}
}

@keyframes _on_opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/
.root {
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
	top: 0;
	left: 0;
	padding-top: 154px;
}

.pc_container,
.pc_tb_container,
.container {
	width: 100%;
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
}

.pc_container-relative,
.pc_tb_container-relative,
.container-relative {
	position: relative;
	top: 0;
	left: 0;
}

.pc_container-sm,
.pc_tb_container-sm,
.container-sm {
	width: 100%;
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
	max-width: 600px;
}

.pc_container-sm2,
.pc_tb_container-sm2,
.container-sm2 {
	width: 100%;
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
	max-width: 300px;
}

.pc_padding,
.padding {
	padding-left: 20px;
	padding-right: 20px;
}

.main_content_area {
	padding-top: 30px;
	position: relative;
	top: 0;
	left: 0;
	padding-bottom: 80px;
	display: flex;
	justify-content: space-between;
}

.main_content_area-cindex {
	display: block;
}

.main_column {
	order: 2;
	width: 100%;
	box-sizing: border-box;
}

.sub_column {
	order: 1;
	width: 260px;
	flex-basis: 260px;
	flex-shrink: 0;
	flex-grow: 0;
	margin-right: 30px;
	box-sizing: border-box;
	overflow: hidden;
}

.main_content {
	font-size: 1rem;
	padding-bottom: 140px;
}

.scroll_to_top {
	right: 20px;
}

@media screen and (min-width: 1120px) {
	.scroll_to_top {
		right: 140px;
	}
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

*/
.pc_none,
.root .pc_none {
	display: none;
}

.block,
.pc_block {
	display: block;
}

@media screen and (min-width: 1000px) and (max-width: 1099px), print {
	.pc_s_block {
		display: block;
	}
}

.tel_link,
.tel_link-no {
	pointer-events: none;
	cursor: default;
	color: inherit;
}

.root .tel_link,
.root .tel_link:hover, .root
.tel_link-no,
.root
.tel_link-no:hover {
	text-decoration: none;
	color: inherit;
}

.sp_click,
.pc_no_click {
	pointer-events: none;
	cursor: default;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************



## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/
/**************************

## - 段組設定 2列 3列 4列

PCの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .pc_column2**

	<ul class="pc_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .pc_column3**

	<ul class="pc_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .pc_column4**

	<ul class="pc_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/
.pc_column2 > *:nth-child(2n) {
	padding-bottom: 20px;
}

.pc_column2-no_mb > *:nth-child(2n) {
	padding-bottom: 0em;
}

.pc_column2 > *:last-child {
	padding-bottom: 0;
}

.pc_column3 > *:nth-child(3n) {
	padding-bottom: 20px;
}

.pc_column3-no_mb > *:nth-child(3n) {
	padding-bottom: 0em;
}

.pc_column3 > *:last-child {
	padding-bottom: 0;
}

.pc_column4 > *:nth-child(4n) {
	padding-bottom: 20px;
}

.pc_column4-no_mb > *:nth-child(4n) {
	padding-bottom: 0em;
}

.pc_column4 > *:last-child {
	padding-bottom: 0;
}

.set-2::before,
.set-2::after {
	content: '';
	display: table;
}

.set-2::after,
.set-3::after {
	clear: both;
}

.pc_column2::before, .pc_column2::after {
	content: '';
	display: table;
}

.pc_column2::after {
	clear: both;
}

.pc_column2 > * {
	width: 50%;
	float: left;
	box-sizing: border-box;
}

.pc_column2 > *:nth-child(2n + 1) {
	clear: left;
	padding-right: 20px;
}

.pc_column2 > *:nth-child(2n) {
	padding-left: 20px;
}

.pc_column3::before, .pc_column3::after {
	content: '';
	display: table;
}

.pc_column3::after {
	clear: both;
}

.pc_column3 > * {
	width: 33.33333%;
	float: left;
	box-sizing: border-box;
}

.pc_column3 > *:nth-child(3n + 1) {
	clear: left;
	padding-right: 27px;
}

.pc_column3 > *:nth-child(3n + 2) {
	padding-left: 13px;
	padding-right: 13px;
}

.pc_column3 > *:nth-child(3n) {
	padding-left: 27px;
}

.pc_column4::before, .pc_column4::after {
	content: '';
	display: table;
}

.pc_column4::after {
	clear: both;
}

.pc_column4 > * {
	width: 25%;
	float: left;
	box-sizing: border-box;
}

.pc_column4 > *:nth-child(4n + 1) {
	clear: left;
	padding-right: 15px;
}

.pc_column4 > *:nth-child(4n + 2) {
	padding-left: 5px;
	padding-right: 10px;
}

.pc_column4 > *:nth-child(4n + 3) {
	padding-left: 10px;
	padding-right: 5px;
}

.pc_column4 > *:nth-child(4n) {
	padding-left: 15px;
}

.pc_column2_2 {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.pc_column2_2 > * {
	width: 360px;
	margin-right: 10px;
}

.pc_column2_2 > *:nth-child(2n) {
	margin-right: 0;
}

.pc_column2_2 > *:nth-child(2n) {
	padding-bottom: 20px;
}

.pc_column2_2 > *:last-child {
	padding-bottom: 0;
}

.set::before, .set::after {
	content: '';
	display: table;
}

.set::after {
	clear: both;
}

.set .resize.right {
	width: 100%;
	box-sizing: border-box;
	float: right;
	padding-left: 450px;
	margin-left: -410px;
}

.set .fix.left {
	width: 410px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: left;
}

.set .resize.left {
	width: 100%;
	box-sizing: border-box;
	float: left;
	padding-right: 450px;
	margin-right: -410px;
}

.set .fix.right {
	width: 410px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: right;
}

.set .fix > img:not(.cancel),
.set .resize > img:not(.cancel) {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 410px;
}

.set-2 .resize.right {
	width: 100%;
	box-sizing: border-box;
	float: right;
	padding-left: 240px;
	margin-left: -200px;
}

.set-2 .fix.left {
	width: 200px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: left;
}

.set-2 .resize.left {
	width: 100%;
	box-sizing: border-box;
	float: left;
	padding-right: 240px;
	margin-right: -200px;
}

.set-2 .fix.right {
	width: 200px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: right;
}

.set-2 .fix > img:not(.cancel),
.set-2 .resize > img:not(.cancel) {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 200px;
}

.set-3 .resize.right {
	width: 100%;
	box-sizing: border-box;
	float: right;
	padding-left: 400px;
	margin-left: -380px;
}

.set-3 .fix.left {
	width: 380px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: left;
}

.set-3 .resize.left {
	width: 100%;
	box-sizing: border-box;
	float: left;
	padding-right: 400px;
	margin-right: -380px;
}

.set-3 .fix.right {
	width: 380px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: right;
}

.set-3 .fix > img:not(.cancel),
.set-3 .resize > img:not(.cancel) {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 380px;
}

.set-4 .resize.right {
	width: 100%;
	box-sizing: border-box;
	float: right;
	padding-left: 400px;
	margin-left: -380px;
}

.set-4 .fix.left {
	width: 380px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: left;
}

.set-4 .resize.left {
	width: 100%;
	box-sizing: border-box;
	float: left;
	padding-right: 400px;
	margin-right: -380px;
}

.set-4 .fix.right {
	width: 380px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: right;
}

.set-4 .fix > img:not(.cancel),
.set-4 .resize > img:not(.cancel) {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 380px;
}

.set-5 .resize.right {
	width: 100%;
	box-sizing: border-box;
	float: right;
	padding-left: 300px;
	margin-left: -260px;
}

.set-5 .fix.left {
	width: 260px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: left;
}

.set-5 .resize.left {
	width: 100%;
	box-sizing: border-box;
	float: left;
	padding-right: 300px;
	margin-right: -260px;
}

.set-5 .fix.right {
	width: 260px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: right;
}

.set-5 .fix > img:not(.cancel),
.set-5 .resize > img:not(.cancel) {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 260px;
}

.footer__2 {
	position: relative;
	top: 0;
	left: 0;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/
.header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	box-sizing: border-box;
}

.header__1 .container {
	display: flex;
	justify-content: space-between;
	height: 90px;
}

.header__1__a {
	padding-top: 10px;
}

.header__1__b {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	padding-bottom: 8px;
}

.header__logo {
	width: 300px;
	height: 80px;
}

.header__sp_tellink {
	display: none;
}

.header__link_area {
	margin-right: 20px;
}

.header__link_area ul {
	display: flex;
}

.header__link_area ul li {
	line-height: 1;
}

.header__link_area ul li:not(:last-child) {
	margin-right: .7em;
}

.header__link_area ul li a {
	font-family: "FOT-ロダンカトレア Pro DB", RodinCattleyaPro-DB, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
	color: #222222;
	font-size: 0.938rem;
	padding-right: 24px;
	position: relative;
	top: 0;
	left: 0;
}

.header__link_area ul li a._medical::after {
	content: '';
	display: inline-block;
	background: url("../img/icon/personal.png") 0 center no-repeat transparent;
	background-size: 20px 18px;
	width: 20px;
	height: 18px;
}

.header__link_area ul li a._contact::after {
	content: '';
	display: inline-block;
	background: url("../img/icon/mail.png") 0 center no-repeat transparent;
	background-size: 20px 16px;
	width: 20px;
	height: 16px;
}

.header__link_area ul li a::after {
	position: absolute;
	bottom: .1em;
	right: 0;
}

.header__link_area ul li a:hover {
	text-decoration: underline;
	color: #0f4c81;
}

.header__tel_area {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-right: 20px;
}

.header__tel_area .label {
	font-family: "FOT-ロダンカトレア Pro M", RodinCattleyaPro-M, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
	font-size: 0.938rem;
}

.header__tel_area .tel {
	width: 230px;
	height: 26px;
}

.header__tel_area .tel,
.header__tel_area .tel img {
	display: block;
	max-width: 100%;
}

.header__search a {
	display: block;
	width: 30px;
	height: 30px;
	background-color: #0f4c81;
}

.header__search a:hover {
	text-decoration: none;
	opacity: 0.7;
	transition: 0.2s opacity;
}

.header__search a,
.header__search img {
	display: block;
	max-width: 100%;
}

.header .open_menu {
	display: none;
}

.gnav__ul {
	display: flex;
	justify-content: center;
	height: 64px;
}

.gnav__li {
	position: relative;
	top: 0;
	left: 0;
}

.gnav__a {
	font-family: "FOT-ロダンカトレア Pro DB", RodinCattleyaPro-DB, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
	font-size: 0.938rem;
	letter-spacing: .1em;
	padding-left: 15px;
	padding-right: 15px;
	height: 64px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
}

.gnav__a::before {
	content: '';
	display: block;
	background: url("../img/icon/arrow/gnav_top.png") 0 0 no-repeat transparent;
	background-size: 20px 10px;
	width: 20px;
	height: 10px;
	position: absolute;
	bottom: 5px;
	left: 50%;
	margin-left: -10px;
}

.gnav__a.active, .gnav__a.hover, .gnav__a:hover {
	background-color: #76d4d5;
	color: #0f4c81;
}

.gnav__sub {
	display: none;
	visibility: hidden;
	position: absolute;
	top: 64px;
	left: 0;
	z-index: 99;
}

.inited .gnav__sub {
	visibility: visible;
}

.gnav__sub__container {
	position: relative;
	width: 300px;
	padding-top: 0;
	top: 0;
	left: 0;
	box-sizing: border-box;
	transition: opacity .5s ease-in-out;
	z-index: 10;
}

.gnav__sub__container-6 .gnav__sub__ul1 {
	left: -503px;
}

.gnav__sub__container-column2 {
	width: 600px;
}

.gnav__sub__container-column2 .gnav__sub__ul1 {
	display: flex;
	flex-wrap: wrap;
	background-color: #62cecf;
}

.gnav__sub__container-column2 .gnav__sub__ul1 > .li1 {
	width: 300px;
}

.gnav__sub__container-column2 .gnav__sub__ul1 > .li1:nth-child(odd) {
	border-right: 1px solid #97f8f9;
	box-sizing: border-box;
}

.gnav__sub__ul1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	padding: 0;
	border-top: 1px solid #97f8f9;
}

.gnav__sub .li1 {
	border-bottom: 1px solid #97f8f9;
	position: relative;
	top: 0;
	left: 0;
}

.gnav__sub .li1 .toggle,
.gnav__sub .li1 .accordion__content {
	display: none;
}

.gnav__sub .a1 {
	font-family: "FOT-ロダンカトレア Pro M", RodinCattleyaPro-M, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
	background-color: #76d4d5;
	display: block;
	padding-top: 10px;
	padding-bottom: 9px;
	padding-left: 15px;
	min-height: 40px;
	box-sizing: border-box;
	color: #0f4c81;
	line-height: 1.33333;
	font-size: 0.938rem;
	position: relative;
	top: 0;
	left: 0;
	padding-right: 15px;
}

.gnav__sub .a1::after {
	content: '';
	display: block;
	background: url("../img/icon/arrow/gnav_sub.png") 0 0 no-repeat transparent;
	background-size: 10px 15px;
	width: 10px;
	height: 15px;
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -5px;
}

.gnav__sub li.active > a,
.gnav__sub li > a:hover {
	font-weight: bold;
	text-decoration: none;
	background-color: #9de0e1;
}

.gnav__sub .li2,
.gnav__sub .li3 {
	display: none;
}

.gnav__sub-show {
	display: block;
}

.gnav__sub-show .anime {
	animation-name: gnav_sub;
	animation-duration: .2s;
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-fill-mode: forwards;
}

.sub_banner_area {
	margin-top: 20px;
}

.img_left {
	float: left;
	padding-right: 20px;
}

.img_right {
	float: right;
	padding-left: 20px;
}

.dock_td {
	width: 15%;
	vertical-align: middle;
	text-align: right;
}

.remodal_pc_search {
	border-radius: 10px;
}

.remodal_pc_search .h-search {
	font-family: "FOT-ロダンカトレア Pro DB", RodinCattleyaPro-DB, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
	font-size: 1.5rem;
	color: #0f4c81;
	margin-bottom: .5em;
	letter-spacing: .1em;
	text-align: center;
}

.remodal_pc_search .search_close_button {
	margin-top: 1em;
	min-width: 12em;
	text-align: center;
	padding-right: 32px;
}

.search {
	display: block;
	position: relative;
	top: 0;
	left: 0;
	box-sizing: border-box;
	width: 100%;
}

.search::before {
	left: 4px;
	z-index: 2;
}

.search__text {
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	border: 1px solid #ccc;
	background-color: #e1e1e1;
	max-width: 100%;
	box-sizing: border-box;
	z-index: 1;
	padding-left: 8px;
	height: 46px;
	line-height: 46px;
	font-size: 1rem;
	-webkit-appearance: none;
	overflow: hidden;
}

.search__text:focus {
	outline: none;
}

.search__submit {
	border: 0 none;
	cursor: pointer;
	-webkit-appearance: none;
	position: absolute;
	top: 1px;
	right: 1px;
	background-color: #e1e1e1;
	width: 44px;
	height: 44px;
	padding: 0;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	transition: opacity .1s ease-in-out;
}

.search__submit:hover::before {
	opacity: .75;
}

.search__submit::before {
	content: '';
	display: block;
	background: url("../img/pc/header_search.png") 0 0 no-repeat transparent;
	background-size: 30px 30px;
	width: 30px;
	height: 30px;
	margin: auto;
}
